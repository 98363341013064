#mask {
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  @include z-index(0, -1);

  .blur & {
    @include z-index(1);
    background-color: rgba(0, 0, 0, 0.25);
  }
}
