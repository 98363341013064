/** Layout **/
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  main {
    flex: 1 0 auto;

    aside {
      flex: 1;
      padding: 0.5rem;

      &:not(:has(~ aside)) {
        display: none;
      }
    }

    &.container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-inline: 1rem;
      gap: 0.5rem;

      &.container-reverse {
        flex-direction: row-reverse;
      }

      &:has(.home:not(.posts)) {
        align-items: center;
      }
    }
  }
}

@import "_header";
@import "_breadcrumb";
@import "_footer";
@import "_pagination";
