.douyin {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60.725%;
  margin: 1rem auto;
  text-align: center;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

@media only screen and (max-width: 680px) {
  .douyin {
    width: 92%;
    margin-inline: 4%;
    padding-bottom: 190%;
  }
}
