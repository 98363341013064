.typeit {
  .highlight {
    padding: 0.375rem;
    font-size: 0.875rem;
    font-family: $code-font-family;
    font-weight: bold;
    word-break: break-all;
    white-space: pre-wrap;
  }

  --ti-cursor-font-family: #{$global-font-family};
  --ti-cursor-transform: translateX(0);
}
