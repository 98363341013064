// ==============================
// Common Styles
// 常用样式
// ==============================

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

.d-none {
  display: none !important;
}

.ms-1,
.mx-1 {
  margin-left: 0.25em !important;
}
.me-1,
.mx-1 {
  margin-right: 0.25em !important;
}
.ps-1,
.px-1 {
  padding-left: 0.25em !important;
}
.pe-1,
.px-1 {
  padding-right: 0.25em !important;
}

@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: var(--#{$prefix}#{$color}) !important;
  }
}

@each $key, $value in $orders {
  .order-#{$key} {
    order: #{$value} !important;
  }
}

.variant-numeric {
  font-variant-numeric: tabular-nums lining-nums;
}
